function showNavbar() {
  var toggleMenu = $(".mobile-nav__toggle");
  var toggleCollapse = $(".mobile-nav__collapse");
  var menuLinks = $(".mobile-nav__inner");
  transitionAnimation = new TimelineMax();

  transitionAnimation
    .set($("body"), { className: "+=js--nav-expanded" })
    .set(toggleMenu, { className: "+=active" })
    .set(menuLinks, {
      opacity: 1,
      y: 0,
      rotation: 0,
      transformOrigin: "right top",
    })
    .to(toggleCollapse, 0.5, { opacity: 1, ease: Power2.easeIn }, 0.2)
    .staggerFrom(
      menuLinks,
      0.4,
      {
        opacity: 0,
        y: 150,
        rotation: 8,
        transformOrigin: "right top",
        ease: Power4.easeInOut,
      },
      0.05,
      0.3
    );
}

function hideNavbar() {
  var toggleMenu = $(".mobile-nav__toggle");
  var toggleCollapse = $(".mobile-nav__collapse");
  var menuLinks = $(".mobile-nav__inner");
  transitionAnimation = new TimelineMax();

  transitionAnimation
    .set(toggleMenu, { className: "-=active" })
    .set(menuLinks, {
      opacity: 1,
      y: 0,
      rotation: 0,
      transformOrigin: "right top",
    })
    .staggerTo(
      menuLinks,
      0.3,
      {
        opacity: 0,
        y: -100,
        rotation: 8,
        transformOrigin: "right top",
        ease: Power4.easeIn,
      },
      0.05,
      0
    )
    .to(toggleCollapse, 0.4, { opacity: 0, ease: Power4.easeInOut }, 0.3)
    .set($("body"), { className: "-=js--nav-expanded" });
}

function navbarMobile() {
  $(".mobile-nav__toggle").click(function () {
    if ($("body").hasClass("js--nav-expanded")) {
      hideNavbar();
    } else if (!$("body").hasClass("js--nav-expanded")) {
      showNavbar();
    }
  });
}

function scrollToggle() {
  var homepage = $("#hero").length > 0;

  if (homepage) {
    // init controller
    var controller = new ScrollMagic.Controller();

    // build scenes
    new ScrollMagic.Scene({
      triggerElement: "#hero",
      triggerHook: 0,
      offset: -110,
      duration: $("#hero").outerHeight(),
    })
      .setClassToggle(".menu-icon", "light") // add class toggle
      // .addIndicators()
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: "#sec2",
      triggerHook: 0,
      offset: -110,
      duration: $("#sec2").outerHeight(),
    })
      .setClassToggle(".menu-icon", "dark") // add class toggle
      // .addIndicators()
      .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: "#sec3",
      triggerHook: 0,
      offset: -110,
      duration: $("#sec3").outerHeight(),
    })
      .setClassToggle(".menu-icon", "light") // add class toggle
      // .addIndicators()
      .addTo(controller);

    // new ScrollMagic.Scene({triggerElement: "#sec4", triggerHook: 0, offset: -110, duration: $("#sec4").outerHeight()})
    //   .setClassToggle(".menu-icon", "dark") // add class toggle
    //   // .addIndicators()
    //   .addTo(controller);

    new ScrollMagic.Scene({
      triggerElement: "#hero",
      triggerHook: 0,
      offset: 5,
      duration: 150,
    })
      // animate color and top border in relation to scroll position
      .setTween(".hero__arrow", { opacity: 0 })
      // .addIndicators({name: "2 (duration: 150)"})
      .addTo(controller);
  }
}
function navbarDesktop() {
  if (window.location.pathname !== "/") {
    return;
  }
  const navDesktop = document.querySelector(".main-nav");
  window.onscroll = function () {
    const pos = document.documentElement.scrollTop;
    if (pos) {
      navDesktop.classList.add("scrolled");
    } else {
      navDesktop.classList.remove("scrolled");
    }
  };
}

// function circles() {
//   if (window.location.pathname !== "/ways-we-support.php") {
//     return;
//   }
//   const dist = ({ x: x0, y: y0 }, { x: x1, y: y1 }) =>
//     (x0 - x1) ** 2 + (y0 - y1) ** 2;
//   const circs = [...document.querySelectorAll(".beliefs-circles div")].map(
//     (el) => ({
//       el,
//     })
//   );
//   circs[0].c = { x: 0.5, y: 0.3 }; //center
//   circs[1].c = { x: 0.3, y: 0.3 + 0.866 * 0.4 };
//   circs[2].c = { x: 0.7, y: 0.3 + 0.866 * 0.4 };
//   for (let circ of circs) {
//     circ.sc = {
//       x: 0.25 + 0.5 * circ.c.x,
//       y: 0.25 + 0.5 * circ.c.y,
//     }; //skewed center
//   }
//   const wrapper = document.querySelector(".beliefs-circles");
//   let bcr = wrapper.getBoundingClientRect();
//   wrapper.onmouseenter = function (e) {
//     bcr = wrapper.getBoundingClientRect();
//     for (let circ of circs) {
//       circ.el.style.transition = "none";
//     }
//   };
//   wrapper.onmousemove = function (e) {
//     const ms = { x: e.offsetX / bcr.width, y: e.offsetY / bcr.height };
//     for (let circ of circs) {
//       const t = 0.8 / (1 + 50 * dist(circ.sc, ms));
//       circ.el.style.background = `#c69c49${Math.floor(200 * t)
//         .toString(16)
//         .padStart(2, "0")}`;
//       circ.el.style.transform = `translate(${
//         bcr.width * t * (0.5 - circ.c.x)
//       }px,${bcr.width * t * (0.5 - circ.c.y)}px)`;
//     }
//   };
//   wrapper.onmouseleave = function (e) {
//     for (let circ of circs) {
//       circ.el.style.transition = "transform 400ms";
//       circ.el.style.transform = "none";
//       circ.el.style.background = "transparent";
//     }
//   };
// }
